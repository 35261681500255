import React from "react"
import { BannerButton, Banner, HomeHeader } from "../utils"
import NotFoundImg from "../images/jelly-bots.png"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <HomeHeader img={NotFoundImg}>
      <Banner
        title="404: Not found"
        subtitle="You just hit a route that doesn't exist... the sadness."
      >
        <Link to="/" style={{ textDecoration: "none" }}>
          <BannerButton Linkstyle={{ margin: "2rem auto" }}>
            Take me Home!
          </BannerButton>
        </Link>
      </Banner>
    </HomeHeader>
  </Layout>
)

export default NotFoundPage
